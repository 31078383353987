
import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";

import BlockForm from "../components/Form.vue";

@Component({
  components: {
    BlockForm
  }
})
export default class BlockEditPage extends Vue {
  @Prop(Number) readonly blockId!: number;

  removeDialog = false;

  formData = {};
  errors = null;

  created() {
    this.fetchBlock();
  }

  get isBeforeToday() {
    return moment(this.formData["date_from"]).isBefore(moment().startOf("day"));
  }

  fetchBlock() {
    this.$store
      .dispatch("schedule/fetchBlock", this.blockId)
      .then(({ data }) => {
        this.formData = {
          id: this.blockId,
          reason_id: data.data.reason.id,
          start_date: data.data.date_from,
          date_from: data.data.date_from,
          end_date: data.data.end_date,
          time_from: data.data.time_from,
          time_to: data.data.time_to,
          days: data.data.days,
          is_multi_task: data.data.is_multi_task,
          reiteration: data.data.reiteration,
          info: data.data.info
        };
      })
      .catch(error => {
        this.errors = error.response.data.errors;
      });
  }

  updateBlock() {
    this.errors = null;

    // 🤷🏻‍♀️
    this.formData["date_from"] = this.formData["start_date"];

    this.$store
      .dispatch("schedule/updateBlock", this.formData)
      .then(() => {
        this.$router.push({ name: "schedule" });
      })
      .catch(error => {
        this.errors = error.response.data.errors;
      });
  }

  removeBlock() {
    this.$store
      .dispatch("schedule/removeBlock", this.blockId)
      .then(() => {
        this.$router.push({ name: "schedule" });
      })
      .catch(error => {
        this.errors = error.response.data.errors;
      });
  }
}
